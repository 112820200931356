<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask
			id="mask0_186_13658"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="20"
			height="20"
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_186_13658)">
			<path
				d="M5.06232 18L6.37482 12.4583L1.99982 8.72914L7.74982 8.22914L9.99982 2.99997L12.2498 8.24997L17.9998 8.72914L13.6248 12.4583L14.9373 18L9.99982 15.0625L5.06232 18Z"
				fill="currentColor"
			/>
		</g>
	</svg>
</template>
